import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import loadable from '@loadable/component';

import CentralLink from '../components/CentralLink.js';
import Loading from '../components/Loading.js';

import getAssetUrl from '../lib/CDNAsset.js';

const Alert = loadable(() => import('react-bootstrap/Alert'));

// const FloodAlertIcon = getAssetUrl('flood-alert-icon-960-h140.webp');
const FloodClearIcon = getAssetUrl('flood-clear-icon-960-h140.webp');
const FloodAlertIcon = getAssetUrl('flood-alert-icon-960-h140.webp');
const FloodWarningIcon = getAssetUrl('flood-warning-icon-960-h140.webp');
const FloodSevereIcon = getAssetUrl('flood-severe-warning-icon-960-h140.webp');
// import FloodWarningIcon from '../images/flood-warning-icon-960.png';

function FloodAlertHeroSummary({ viewHome }) {
	let topFloodAlertBanner = null;



	if(viewHome && viewHome.floodAlerts) {
		let severe = false, hasAlerts = false;
		// Calculate component to display
		
		let imageSrc = FloodClearIcon;
		let imageAlt = "The icon is displaying that the flood alert summary situation is 'all clear'. There are no flood warnings or alerts in effect.";
		if(viewHome.floodAlerts.severity3) {
			// Has Alerts
			hasAlerts = true;
			imageSrc = FloodAlertIcon;
			imageAlt = "The icon is displaying that the highest level of flood warning in the country is currently an 'Alert'. An 'Alert' indicates flooding is possible. Check your local flood warning area to see if this warning affects you."
		}
		if(viewHome.floodAlerts.severity2) {
			// Has Warning
			hasAlerts = true;
			imageSrc = FloodWarningIcon;
			imageAlt = "The icon is displaying that the highest level of flood warning in the country is currently a 'Warning'. A 'Warning' indicates that flooding is expected or already in progress. Check your local flood warning area to see if this warning affects you."
		}
		if(viewHome.floodAlerts.severity1) {
			// Has Severe
			hasAlerts = true;
			severe = true;
			imageSrc = FloodSevereIcon;
			imageAlt = "The icon is displaying that the highest level of flood warning in the country is currently a 'Severe Warning'. Severe flood warnings may be issued before, during or after flooding. This indicates significant disruption and/or a risk to life. Immediate action is required. Check your local flood warning area to see if this warning affects you."
		}


		topFloodAlertBanner = <Row className="align-items-center">
			<Col xs={4} className="d-flex justify-content-center align-items-center p-3">
				<CentralLink to={"/flood-alerts"}>
					<Card.Img src={imageSrc} style={{ maxWidth: "95%", maxHeight: "140px", objectFit: "contain", "cursor": "pointer" }} alt={imageAlt}/>
				</CentralLink>
			</Col>
			<Col xs={8}>
				{hasAlerts === true ? <span>There are currently <b>{viewHome.floodAlerts.severity3}</b> alerts and <b>{viewHome.floodAlerts.severity2+viewHome.floodAlerts.severity1}</b> warnings for flooding in effect across the UK.</span> : "There are currently no warnings or alerts in effect across the UK."}<br />
				<h3>
					<CentralLink to={"/flood-alerts"}>
						<Button variant={severe ? "danger" : "dark"} size="sm">View UK Warnings</Button>
					</CentralLink>
				</h3>
			</Col>{severe ? <Col xs={12}>
				<Alert variant="danger"><b>A severe warning has been issued</b><br />These types of warnings are rare and are usually issued when there is a risk to life. Please check warnings in your local area.</Alert>
			</Col> : null}
		</Row>;
	} else {
		topFloodAlertBanner = <Row className="align-items-center">
			<Col xs={12}><center><Loading style={{maxHeight: "80px"}}/></center></Col>
		</Row>;
	}

	return topFloodAlertBanner;
}

export default FloodAlertHeroSummary;