import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CentralLink from './CentralLink.js';
import Button from 'react-bootstrap/Button';


function ProminentLinkCard({ image, title, body, buttonText, navigationTarget, imageAlt }) {
	let buttonTextComputed = buttonText ? buttonText : "Learn More";
	return (
		<Card className="h-100 shadow-sm">
			<Row className="align-items-center">
				<Col xs={4} className="d-flex justify-content-center align-items-center p-3">
					<CentralLink to={navigationTarget}>
						<Card.Img src={image} style={{ maxWidth: "95%", maxHeight: "140px", objectFit: "contain", "cursor": "pointer" }} alt={imageAlt} />
					</CentralLink>
				</Col>
				<Col xs={8}>
					<Card.Body>
						<CentralLink to={navigationTarget}>
							<Card.Title style={{ color: "rgb(33, 37, 41)", fontWeight: "bold", "cursor": "pointer" }} ><h2 className="h2-blendin">{title}</h2></Card.Title>
						</CentralLink>
						<Card.Text style={{ fontSize: "0.9rem" }}>
						  {body}
						</Card.Text>
						<div className="mt-3">
						  <CentralLink to={navigationTarget}>
						  	<Button variant="dark" size="sm" >{buttonTextComputed}</Button>
						  </CentralLink>
						</div>
					</Card.Body>
				</Col>
			</Row>
		</Card>
	);
}


export default ProminentLinkCard;