import React from 'react';
import { Helmet } from 'react-helmet';

import { useFM } from '../../FMContext';

export const CRUMBS = {
    HOME: { name: 'Home', path: '/' },
    DATA_EXPLORER: { name: 'Data Explorer', path: '/data-explorer' },
    FIND_A_STATION_UK: { name: 'Find a Station (UK)', path: '/data-explorer/stationmap/uk'},
    FIND_A_STATION_IE: { name: 'Find a Station (IE)', path: '/data-explorer/stationmap/ie'},
    SEARCH_SEWAGE: { name: 'Sewage Reports', path: '/data-explorer/search-sewage-report'},
    RIVER_BASINS: { name: 'River Basins', path: '/data-explorer/basins'},
    MANAGEMENT_CATCHMENTS: { name: 'Management Catchments', path: '/data-explorer/management-catchments'},
    OPERATIONAL_CATCHMENTS: { name: 'Operational Catchments', path: '/data-explorer/operational-catchments'},
};

const Breadcrumbs = ({ builtinCrumbs, leafCrumbs }) => {
	const { currentSubdomain, currentHostname, currentProtocol, currentPort } = useFM();
    const breadcrumbList = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": (builtinCrumbs || []).concat((leafCrumbs || [])).map((breadcrumb, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "name": breadcrumb.name,
            "item": `${currentProtocol}://${currentSubdomain}.${currentHostname}${breadcrumb.path}`
        }))
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(breadcrumbList)}
            </script>
        </Helmet>
    );
};

export default Breadcrumbs;



