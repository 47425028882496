import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';

import CommonHeaders from '../components/helmet-headers/CommonHeaders.js';

import Alert from 'react-bootstrap/Row';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import getAssetUrl from '../lib/CDNAsset.js';

import CentralLink from '../components/CentralLink.js';

import ProminentLinkCard from '../components/ProminentLinkCard.js';

import { getHomePageView } from '../store/reducers/fmAPI.js';

import FloodAlertHeroSummary from '../components/FloodAlertHeroSummary.js';

import Breadcrumbs, { CRUMBS } from '../components/helmet-headers/Breadcrumbs.js';

const FloodNav2 = getAssetUrl('FloodNav2-h140.webp');
const DataExplorer = getAssetUrl('DataExplorer-h140.webp');



const FloodAlertIcon = getAssetUrl('flood-alert-icon-960-h140.webp');

function HomePage(props) {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const { viewHome, errorList } = useSelector(state => state.fmAPI);

	// const dispatch = useDispatch();
	// const { basins, fmCoverageAreas } = useSelector(state => state.fmAPI);

	// useEffect(() => {
		// dispatch(getBasins());
		// dispatch(getFloodMapperCoverageAreas());
	// }, [dispatch]);

	let callerTrace = "homePageView";
	useEffect(() => {
		dispatch(getHomePageView(callerTrace));
	}, [dispatch, callerTrace])

	let topFloodAlertBanner = null;
	topFloodAlertBanner = <FloodAlertHeroSummary viewHome={viewHome} />
	if(errorList && errorList[callerTrace]) {
		topFloodAlertBanner = <Row className="align-items-center">
			<Col xs={4} className="d-flex justify-content-center align-items-center p-3">
				<CentralLink to={"/flood-alerts"}>
					<Card.Img src={FloodAlertIcon} style={{ maxWidth: "95%", maxHeight: "140px", objectFit: "contain", "cursor": "pointer" }} alt="The icon here denotes a flood alert, this component normally reflects the highest alert level set through the country at this time, but there was an error retrieving or processing the data and this icon is our fallback position. To view the current state navigate via this component to the flood alert summary page." />
				</CentralLink>
			</Col>
			<Col xs={8}>
				<i>Error loading alert summary</i>
				<h3>
					<CentralLink to={"/flood-alerts"}>
						<Button variant="dark" size="sm">View UK Warnings</Button>
					</CentralLink>
				</h3>
			</Col>
		</Row>;
	}


	return (
	<div>
		<CommonHeaders
			canonicalUrlPath="/"
			canonicalDefaultWebsite="UK"
		/>
		<Breadcrumbs
			builtinCrumbs={[CRUMBS.HOME]}
		/>
		<Row className="align-items-center">
			<Col xs={12} className="text-white bg-dark"><h1 className="h1-welcome-to-floodmapper">{t('PAGES.HOME.WELCOME_TO_FLOODMAPPER')}</h1></Col>
		</Row>
		{topFloodAlertBanner}
		<Row>
			<Col className="text-white bg-dark">&nbsp;</Col>
		</Row>
	  	<div style={{ paddingTop: "2%" }}>
		    <Row>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    	image={DataExplorer}
			    	imageAlt={t('PROMLINKCARDS.FIND_A_STATION.FIND_A_STATION_IMGALT')}
			    	title={t('PROMLINKCARDS.FIND_A_STATION.FIND_A_STATION_TITLE')}
			    	body={t('PROMLINKCARDS.FIND_A_STATION.FIND_A_STATION_BODY')}
			    	buttonText={t('PROMLINKCARDS.FIND_A_STATION.VIEW_STATION_MAP')}
			    	navigationTarget="/data-explorer/stationmap"
		    	/>
			    </Col>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    		image={FloodNav2}
			    		imageAlt={t('PROMLINKCARDS.FIND_A_FLOODNAV.FIND_A_FLOODNAV_IMGALT')}
			    		title={t('PROMLINKCARDS.FIND_A_FLOODNAV.FIND_A_FLOODNAV_TITLE')}
			    		body={t('PROMLINKCARDS.FIND_A_FLOODNAV.FIND_A_FLOODNAV_BODY')}
			    		buttonText={t('PROMLINKCARDS.FIND_A_FLOODNAV.VIEW_FLOODNAVS')}
			    		navigationTarget="/floodnav"
			    	/>
			    </Col>
			    <Col xs={12} md={6}>
			    	<ProminentLinkCard
			    	image={DataExplorer}
			    	imageAlt={t('PROMLINKCARDS.DATA_EXPLORER.DATA_EXPLORER_IMGALT')}
			    	title={t('PROMLINKCARDS.DATA_EXPLORER.DATA_EXPLORER_TITLE')}
			    	body={t('PROMLINKCARDS.DATA_EXPLORER.DATA_EXPLORER_BODY')}
			    	buttonText={t('PROMLINKCARDS.DATA_EXPLORER.VIEW_DATA_EXPLORER')}
			    	navigationTarget="/data-explorer"
		    	/>
			    </Col>
			</Row>
	  	</div>
	 </div>
);




}


export default HomePage;